import { ActionTree, MutationTree } from 'vuex'
import { IEstrattoState } from '@/store/modules/estratto/interfaces/IEstrattoState'
import { IEstrattoMutations } from '@/store/modules/estratto/interfaces/IEstrattoMutations'
import { EstrattoMutationType } from '@/store/modules/estratto/interfaces/mutation-type'
import { IDettaglio, IEstratto, IRiepilogo } from '@/store/modules/estratto/interfaces/api/IEstrattoResponse'
import { IRootState } from '@/store'
import { IEstrattoActions } from '@/store/modules/estratto/interfaces/IEstrattoActions'
import { EstrattoActionType } from '@/store/modules/estratto/interfaces/action-type'
import { estrattoAPI } from '@/store/modules/estratto/api/estratto'
import { IDettaglioRequest, IEstrattoRequest, IRiepilogoRequest } from '@/store/modules/estratto/interfaces/api/IEstrattoRequest'

const state = (): IEstrattoState => ({
  estratto: [],
  totalEstratto: 0,
  riepilogo: [],
  totalRiepilogo: 0,
  dettaglio: [],
  totalDettaglio: 0,
  loading: false
})

const getters = {
  typeInformation () {
    return [
      { code: 'Codice', description: 'Descrizione' },
      { code: 'XR', description: 'XAB da Stabilimento a Cliente' },
      { code: 'XS', description: 'XAB da Stabilimento a Deposito' },
      { code: 'XD', description: 'XAB da Deposito a Cliente' },
      { code: 'XC', description: 'XAB da Distributore a Cliente' },
      { code: 'V5', description: 'Resa a Stabilimento' },
      { code: 'V6', description: 'Resa a Distributore' },
      { code: 'V7', description: 'Resa a Deposito' },
      { code: 'V8', description: 'Resa a Vettore' },
      { code: 'FT', description: 'Fattura per indennizzo' },
      { code: 'RC', description: 'Rettifica contabile' },
      { code: 'NC', description: 'Nota Credito a Storno Fattura' },
      { code: 'MR', description: 'Merce Resa' }
    ]
  }
}

const mutations: MutationTree<IEstrattoState> & IEstrattoMutations = {
  [EstrattoMutationType.SET_LOADING] (state: IEstrattoState, payload: boolean) {
    state.loading = payload
  },
  async [EstrattoMutationType.SET_ESTRATTO] (state: IEstrattoState, payload: IEstratto[]) {
    state.estratto = payload
  },
  async [EstrattoMutationType.SET_ESTRATTO_TOTAL] (state: IEstrattoState, payload: number) {
    state.totalEstratto = payload
  },
  async [EstrattoMutationType.SET_RIEPILOGO] (state: IEstrattoState, payload: IRiepilogo[]) {
    state.riepilogo = payload
  },
  async [EstrattoMutationType.SET_RIEPILOGO_TOTAL] (state: IEstrattoState, payload: number) {
    state.totalRiepilogo = payload
  },
  async [EstrattoMutationType.SET_DETTAGLIO] (state: IEstrattoState, payload: IDettaglio[]) {
    state.dettaglio = payload
  },
  async [EstrattoMutationType.SET_DETTAGLIO_TOTAL] (state: IEstrattoState, payload: number) {
    state.totalDettaglio = payload
  }
}

const actions: ActionTree<IEstrattoState, IRootState> & IEstrattoActions = {
  async [EstrattoActionType.FETCH_ESTRATTO] ({ commit }, dto: IEstrattoRequest) {
    commit(EstrattoMutationType.SET_LOADING, true)
    const data = await estrattoAPI.fetchEstrattoConto(dto)
    commit(EstrattoMutationType.SET_ESTRATTO, data.list)
    commit(EstrattoMutationType.SET_ESTRATTO_TOTAL, data.total)
    commit(EstrattoMutationType.SET_LOADING, false)
  },
  async [EstrattoActionType.FETCH_RIEPILOGO_MATERIALE] ({ commit }, dto: IRiepilogoRequest) {
    commit(EstrattoMutationType.SET_LOADING, true)
    const data = await estrattoAPI.fetchRiepilogo(dto)
    commit(EstrattoMutationType.SET_RIEPILOGO, data.list)
    commit(EstrattoMutationType.SET_RIEPILOGO_TOTAL, data.total)
    commit(EstrattoMutationType.SET_LOADING, false)
  },
  async [EstrattoActionType.FETCH_DETTAGLIO_MOVIMENTI] ({ commit }, dto: IDettaglioRequest) {
    commit(EstrattoMutationType.SET_LOADING, true)
    const data = await estrattoAPI.fetchDettaglio(dto)
    commit(EstrattoMutationType.SET_DETTAGLIO, data.list)
    commit(EstrattoMutationType.SET_DETTAGLIO_TOTAL, data.total)
    commit(EstrattoMutationType.SET_LOADING, false)
  }
}

const EstrattoModule = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}

export default EstrattoModule
