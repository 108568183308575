import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { IBasketState } from '@/store/modules/basket/interfaces/IBasketState'
import { IBasketAction } from '@/store/modules/basket/interfaces/IBasketAction'
import { IBasketMutation } from '@/store/modules/basket/interfaces/IBasketMutation'
import { BasketMutationTypes } from '@/store/modules/basket/interfaces/mutation-types'
import { IFetchBasketRequest } from '@/store/modules/basket/interfaces/fetchProducts/IFetchBasketRequest'
import { BasketActionTypes } from '@/store/modules/basket/interfaces/action-types'
import { apolloClient } from '@/store/graphql.config'
import { IFetchBasketResponse } from '@/store/modules/basket/interfaces/fetchProducts/IFetchBasketResponse'
import { IBasketProduct } from '@/store/modules/basket/interfaces/IBasketProduct'
import { fetchCustomerBasketQuery } from './queries/fetchCustomerBasket.query'
import { fetchProductsToCartMutation } from './mutations/fetchProductsToCart.mutation'
import { IAddToBasketResponse } from './interfaces/addProductToBasket/IAddToBasketResponse'
import { IAddToBasketRequest } from './interfaces/addProductToBasket/IAddToBasketRequest'
import { IRemoveFromBasketResponse } from './interfaces/removeFromBasket/IRemoveFromBasketResponse'
import { IRemoveFromBasketRequest } from './interfaces/removeFromBasket/IRemoveFromBasketRequest'
import { fetchRemoveCardItemMutation } from './mutations/fetchRemoveCardItem.mutation'
import { fetchUpdateCartItemsMutation } from './mutations/fetchUpdateCartItems.mutation'
import { fetchUpdatePricesMutation } from './mutations/fetchUpdatePrices.mutation'
import { IUpdateItemsRequest } from './interfaces/updateItems/IUpdateItemsRequest'
import { IUpdateItemsResponse } from './interfaces/updateItems/IUpdateItemsResponse'
import { IUpdatePricesRequest } from './interfaces/updatePrices/IUpdatePricesRequest'
import { IUpdatePricesResponse } from './interfaces/updatePrices/IUpdatePricesResponse'
import { fetchPlaceOrderMutation } from './mutations/fetchPlaceOrder.mutation'
import { IPlaceOrderRequest } from './interfaces/placeOrder/IPlaceOrderRequest'
import { IPlaceOrderResponse } from './interfaces/placeOrder/IPlaceOrderResponse'
import { fetchShippingAddressMutation } from './mutations/fetchShippingAddress.mutation'
import { ISetShippingAddressRequest } from './interfaces/setShippingAddress/ISetShippingAddressRequest'
import { ISetShippingAddressResponse } from './interfaces/setShippingAddress/ISetShippingAddressResponse'
import { fetchShippingMethodMutation } from './mutations/fetchShippingMethod.mutation'
import { ISetShippingMethodRequest } from './interfaces/setShippingMethod/ISetShippingMethodRequest'
import { ISetShippingMethodResponse } from './interfaces/setShippingMethod/ISetShippingMethodResponse'
import { fetchCreateBasketMutation } from './mutations/fetchCreateBasket.mutation'
import { ISetEmptyCartResponse } from './interfaces/emptyCart/ISetEmptyCartResponse'
import { fetchPaymentMethodMutation } from './mutations/fetchPaymentMethod.mutation'
import { ISetPaymentMethodRequest } from './interfaces/setPaymentMethod/ISetPaymentMethodRequest'
import { ISetPaymentMethodResponse } from './interfaces/setPaymentMethod/ISetPaymentMethodResponse'
import { fetchCartQuery } from './queries/fetchCartQuery.query'
import { ICartQueryRequest } from './interfaces/cartQuery/ICartQueryRequest'
import { ICartQueryResponse } from './interfaces/cartQuery/ICartQueryResponse'
import { IBasketQuery } from './interfaces/cartQuery/IBasketQuery'
import { IError } from '@/store/modules/catalog/interfaces/common/IError'
import { fetchTransportTypeMutation } from './mutations/fetchTransportType.mutation'
import { ISetTransportTypeRequest } from './interfaces/fetchTransportType/ISetTransportTypeRequest'
import { ISetTransportTypeResponse } from './interfaces/fetchTransportType/ISetTransportTypeResponse'
import { addSaveOrderForLaterQuery } from './queries/addSaveOrderForLater.query'
import { IAddSaveOrderForLaterRequest, IAddSaveOrderForLaterResponse } from './interfaces/addSaveOrderForLater'
import i18n from '@/i18n'
import { fetchMinCartQuery } from './queries/fetchMinCartQuery.query'
import { constants } from '@/constants'
import { fetchEmptiesQuery } from './queries/fetchEmpties.query'
import { IFetchEmptiesRequest } from './interfaces/fetchEmpties/IFetchEmptiesRequest'
import { IFetchEmptiesResponse } from './interfaces/fetchEmpties/IFetchEmptiesResponse'
import { updateEmptiesQuery } from './queries/updateEmpties.query'
import { IUpdateEmptiesRequest } from './interfaces/updateEmpties/IUpdateEmptiesRequest'
import { IUpdateEmptiesResponse } from './interfaces/updateEmpties/IUpdateEmptiesResponse'
import { fetchRemoveAllCardItemSMutation } from './queries/fetchRemoveAllCardItems.query'
import { IRemoveAllBasketItemsResponse } from './interfaces/removeAllItems/IRemoveAllBasketItemsResponse'
import { IRemoveAllBasketItemsRequest } from './interfaces/removeAllItems/IRemoveAllBasketItemsRequest'
import { createSuggestedCartQuery } from './queries/createSuggestedCart.query'
import { ICreateSuggestedCartRequest, ICreateSuggestedCartResponse } from './interfaces/suggestedCart'
import { fetchOrderSummaryQuery } from './queries/fetchOrderSummaryQuery.query'
const state = (): IBasketState => ({
  id: '',
  emptyCartId: '',
  items: [],
  loading: false,
  error: null,
  query: {} as IBasketQuery,
  customer: {},
  billingAddress: {},
  shippingAddress: [],
  shippingMethods: [],
  availablePaymentMethods: null,
  paymentMethod: {},
  prices: {},
  order: {},
  deliveryDate: '',
  shipmentId: '',
  transportType: {},
  progressBarMsg: '',
  empties: {} as any,
  defaultEmpties: {} as any
})

const getters = {
  getPaymentMethods () {
    return [
      { group: 1, code: '3C10', helper: 10, method: 'Contanti', sapTranscodification: 'Contanti a 10 gg. data consegna', label: 'Contanti a 10 gg. data consegna', value: '3C10' },
      { group: 2, code: '2000', helper: 0, method: 'Bonifico', sapTranscodification: 'Bonifico anticipato', label: 'Bonifico anticipato', value: '2000' },
      { group: 2, code: '2C10', helper: 10, method: 'Bonifico', sapTranscodification: 'Bonifico 10 gg. data consegna', label: 'Bonifico a 10 gg. data consegna', value: '2C10' },
      { group: 2, code: '2C15', helper: 15, method: 'Bonifico', sapTranscodification: 'Bonifico a 15 gg. data consegna', label: 'Bonifico a 15 gg. data consegna', value: '2C15' },
      { group: 2, code: '2C30', helper: 30, method: 'Bonifico', sapTranscodification: 'Bonifico a 30 gg. data consegna', label: 'Bonifico a 30 gg. data consegna', value: '2C30' },
      { group: 2, code: '2C45', helper: 45, method: 'Bonifico', sapTranscodification: 'Bonifico a 45 gg. data consegna', label: 'Bonifico a 45 gg. data consegna', value: '2C45' },
      { group: 2, code: '2C60', helper: 60, method: 'Bonifico', sapTranscodification: 'Bonifico a 60 gg. data consegn', label: 'Bonifico a 60 gg. data consegna', value: '2C60' },
      { group: 3, code: '1C10', helper: 10, method: 'Assegno', sapTranscodification: 'Assegno a 10 gg. data consegna', label: 'Assegno a 10 gg. data consegna', value: '1C10' },
      { group: 3, code: '1C15', helper: 15, method: 'Assegno', sapTranscodification: 'Assegno a 15 gg. data consegna', label: 'Assegno a 15 gg. data consegna', value: '1C15' },
      { group: 3, code: '1C30', helper: 30, method: 'Assegno', sapTranscodification: 'Assegno a 30 gg. data consegna', label: 'Assegno a 30 gg. data consegna', value: '1C30' },
      { group: 3, code: '1C45', helper: 45, method: 'Assegno', sapTranscodification: 'Assegno a 45 gg. data consegna', label: 'Assegno a 45 gg. data consegna', value: '1C45' },
      { group: 3, code: '1C60', helper: 60, method: 'Assegno', sapTranscodification: 'Assegno a 60 gg. data consegna', label: 'Assegno a 60 gg. data consegna', value: '1C60' },
      { group: 4, code: '2G10', helper: 10, method: 'Bonifico', sapTranscodification: 'Bonifico a 10 gg. data consegna fine mese', label: 'Bonifico a 10 gg. data consegna fine mese', value: '2G10' },
      { group: 4, code: '2G15', helper: 15, method: 'Bonifico', sapTranscodification: 'Bonifico a 15 gg. data consegna fine mese', label: 'Bonifico a 15 gg. data consegna fine mese', value: '2G15' },
      { group: 4, code: '2G30', helper: 30, method: 'Bonifico', sapTranscodification: 'Bonifico a 30 gg. data consegna fine mese', label: 'Bonifico a 30 gg. data consegna fine mese', value: '2G30' },
      { group: 5, code: 'IC10', helper: 10, method: 'SDD', sapTranscodification: 'SDD a 10 gg. data consegna', label: 'SDD a 10 gg. data consegna', value: 'IC10' },
      { group: 5, code: 'IC15', helper: 15, method: 'SDD', sapTranscodification: 'SDD a 15 gg. data consegna', label: 'SDD a 15 gg. data consegna', value: 'IC15' },
      { group: 5, code: 'IC30', helper: 30, method: 'SDD', sapTranscodification: 'SDD a 30 gg. data consegna', label: 'SDD a 30 gg. data consegna', value: 'IC30' },
      { group: 5, code: 'IC45', helper: 45, method: 'SDD', sapTranscodification: 'SDD a 45 gg. data consegna', label: 'SDD a 45 gg. data consegna', value: 'IC45' },
      { group: 5, code: 'IG30', helper: 45, method: 'SDD', sapTranscodification: 'SDD a 30 gg. dt cons fine mese', label: 'SDD a 30 gg. dt cons fine mese', value: 'IG30' },
      { group: 5, code: 'IC60', helper: 60, method: 'SDD', sapTranscodification: 'SDD a 60 gg. data consegna', label: 'SDD a 60 gg. data consegna', value: 'IC60' },
      { group: 6, code: 'RC30', helper: 30, method: 'Ri.Ba.', sapTranscodification: 'Ri.Ba. a 30 gg. data consegna', label: 'Ri.Ba. a 30 gg. data consegna', value: 'RC30' },
      { group: 6, code: 'RC45', helper: 45, method: 'Ri.Ba.', sapTranscodification: 'Ri.Ba. a 45S gg. data consegna', label: 'Ri.Ba. a 45S gg. data consegna', value: 'RC45' },
      { group: 6, code: 'RC60', helper: 60, method: 'Ri.Ba.', sapTranscodification: 'Ri.Ba. a 60 gg. data consegna', label: 'Ri.Ba. a 60 gg. data consegna', value: 'RC60' }
    ]
  },

  /**
   * progressbar message setup for precart component on Peroni before accessing the catalog
   * @param state
   * @returns string
   */
  progressBarMessage: (state: IBasketState) => {
    const progressBar = state.query?.progress_bar_data
    if (progressBar) {
      if (localStorage.getItem('customertype') === 'On Premise') {
        if (progressBar?.iTotalCartWeight <= progressBar?.iMinimumOrderWeight) {
          return i18n.global.t('You have not reached the minimum order quantity, please add more goods')
        }
        if (progressBar?.iTotalCartWeight > progressBar?.iMaximumOrderWeight) {
          return i18n.global.t('The weight of the order exceeds the maximum order quantity, reduce the quantity to proceed with the order')
        }
        if (progressBar?.iTotalCartWeight >= progressBar?.iMinimumOrderWeight && progressBar?.iTotalCartWeight < constants.cartWeight.minOrderReach) {
          return i18n.global.t('You have not reached yet Motrice discount, please add more quantity')
        }
        if (progressBar?.iTotalCartWeight > constants.cartWeight.minMotriceDiscount && progressBar?.iTotalCartWeight <= constants.cartWeight.motriceDiscountReached) {
          return i18n.global.t('You have reached Motrice discount (discount allowed between 12000 kg and 15999 kg)')
        }

        if (progressBar?.iTotalCartWeight >= constants.cartWeight.minAutotrenoDiscount && progressBar?.iTotalCartWeight <= constants.cartWeight.AutotrenoDiscountNotReached) {
          return i18n.global.t('You have not reached yet Autotreno discount, please add more quantity')
        }
        if (progressBar?.iTotalCartWeight >= constants.cartWeight.minAutotrenoDiscountNotReached && progressBar?.iTotalCartWeight <= progressBar?.iMaximumOrderWeight) {
          return i18n.global.t('You have reached Autotreno discount (discount allowed reached 25260 kg)')
        }
      }
      if (localStorage.getItem('customertype') === 'On Premise Franchisee') {
        if (progressBar?.iTotalCartWeight <= progressBar?.iMinimumOrderWeight) {
          return i18n.global.t('You have not reached the minimum order quantity, please add more goods')
        }
        if (progressBar?.iTotalCartWeight >= progressBar?.iMinimumOrderWeight && progressBar?.iTotalCartWeight <= progressBar?.iMaximumOrderWeight) {
          return i18n.global.t('The minimum order quantity has been reached')
        }
        if (progressBar?.iTotalCartWeight > progressBar.iMaximumOrderWeight) {
          return i18n.global.t('The weight of the order exceeds the maximum order quantity, reduce the quantity to proceed with the order')
        }
      }
    }
    return ''
  }
}

const mutations: MutationTree<IBasketState> & IBasketMutation = {
  [BasketMutationTypes.SET_EMPTY_CART] (state, payload: string): void {
    state.emptyCartId = payload
  },
  [BasketMutationTypes.SET_CART_QUERY] (state, payload: any): void {
    state.query = payload
  },
  [BasketMutationTypes.SET_BASKET_ID] (state, payload: string): void {
    state.id = payload
  },
  [BasketMutationTypes.SET_BASKET_ITEMS] (state, payload: IBasketProduct[]): void {
    state.items = payload
  },
  [BasketMutationTypes.SET_CUSTOMER] (state, payload: any): void {
    state.customer = payload
  },
  [BasketMutationTypes.SET_ERROR] (state, payload: unknown): void {
    state.error = payload
  },
  [BasketMutationTypes.SET_LOADING] (state, payload: boolean): void {
    state.loading = payload
  },
  [BasketMutationTypes.SET_BILLING_ADDRESS] (state, payload: any): void {
    state.billingAddress = payload
  },
  [BasketMutationTypes.SET_SHIPPING_ADDRESS] (state, payload: any): void {
    state.shippingAddress = payload
  },
  [BasketMutationTypes.SET_SHIPPING_METHODS] (state, payload: any): void {
    state.shippingMethods = payload
  },
  [BasketMutationTypes.PAYMENT_METHOD] (state, payload: any): void {
    state.paymentMethod = payload
  },
  [BasketMutationTypes.SET__AVAILABLE_PAYMENT_METHOD] (state, payload: any): void {
    state.availablePaymentMethods = payload
  },
  [BasketMutationTypes.SET_PRICES] (state, payload: any): void {
    state.prices = payload
  },
  [BasketMutationTypes.SET_ORDER] (state, payload: any): void {
    state.order = payload
  },
  [BasketMutationTypes.SET_DELIVERY_DATE] (state, payload: string): void {
    state.deliveryDate = payload
  },
  [BasketMutationTypes.SET_SHIPMENT_ID] (state, payload: string): void {
    state.shipmentId = payload
  },
  [BasketMutationTypes.SET_TRANSPORT_TYPE] (state, payload: string): void {
    state.transportType = payload
  },
  [BasketMutationTypes.SET_PROGRESS_BAR] (state, payload: string): void {
    state.progressBarMsg = payload
  },
  [BasketMutationTypes.SET_EMPTIES] (state, payload: any): void {
    state.empties = payload
  },
  [BasketMutationTypes.SET_DEFAULT_EMPTIES] (state, payload: any): void {
    state.defaultEmpties = payload
  }
}

const actions: ActionTree<IBasketState, IRootState> & IBasketAction = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [BasketActionTypes.CREATE_EMPTY_CART] ({ commit }) {
    await apolloClient.mutate<ISetEmptyCartResponse, object>({
      mutation: fetchCreateBasketMutation
    })
  },
  async [BasketActionTypes.FETCH_CART_QUERY] ({ commit }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchCartQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
    commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
    commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
  },
  async [BasketActionTypes.FETCH_MIN_CART_QUERY] ({ commit }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchMinCartQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    if (data) {
      commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
      commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
      commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
      if (data?.cart?.ship_to_id) {
        localStorage.setItem('shipToId', data.cart.ship_to_id)
      }
      if (data?.cart?.delivery_date) {
        localStorage.setItem('deliveryDate', data.cart.delivery_date)
      }
      if (data?.cart?.delivery_method) {
        localStorage.setItem('shippingMethod', data.cart.delivery_method)
      }
    }
  },
  async [BasketActionTypes.FETCH_BASKET_ID] ({ commit }) {
    commit(BasketMutationTypes.SET_LOADING, true)
    const { data } = await apolloClient.query<IFetchBasketResponse, IFetchBasketRequest>({
      query: fetchCustomerBasketQuery
    })
    try {
      if (data) {
        commit(BasketMutationTypes.SET_BASKET_ID, data?.customerCart.id)
        commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('fetch basket error', error)
    }
  },
  async [BasketActionTypes.FETCH_ADD_TO_BASKET] ({ commit }, dto: IAddToBasketRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const { data } = await apolloClient.mutate<IAddToBasketResponse, IAddToBasketRequest>({
        mutation: fetchProductsToCartMutation,
        variables: {
          cart_id: dto.cart_id,
          sku: dto.sku,
          quantity: dto.quantity,
          number_of_layer: dto.number_of_layer,
          number_of_pallet: dto.number_of_pallet,
          selected_options: dto.selected_options
        }
      })
      if (data?.addProductsToCart.cart.items) {
        commit(BasketMutationTypes.SET_LOADING, false)
        commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.addProductsToCart?.cart?.items)
        commit(BasketMutationTypes.SET_CART_QUERY, { progress_bar_data: data?.addProductsToCart?.cart?.progress_bar_data })
      }
    } catch (error: any) {
      console.error('add to cart error:', error)
      commit(BasketMutationTypes.SET_LOADING, false)
      throw new Error(error.message)
    }
  },
  async [BasketActionTypes.REMOVE_ITEM] ({ commit }, dto: IRemoveFromBasketRequest) {
    commit(BasketMutationTypes.SET_LOADING, true)
    try {
      const { data } = await apolloClient.mutate<IRemoveFromBasketResponse, IRemoveFromBasketRequest>({
        mutation: fetchRemoveCardItemMutation,
        variables: {
          cart_id: dto.cart_id,
          cart_item_id: dto.cart_item_id
        }
      })
      if (data?.removeItemFromCart?.cart?.items) {
        commit(BasketMutationTypes.SET_LOADING, false)
        commit(BasketMutationTypes.SET_CART_QUERY, { items: data?.removeItemFromCart?.cart?.items })
        commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.removeItemFromCart?.cart?.items)
      }
    } catch (error: any) {
      console.error('remove item error:', error)
      commit(BasketMutationTypes.SET_LOADING, false)
      throw new Error(error.message)
    }
  },
  async [BasketActionTypes.REMOVE_ALL_ITEMS] ({ commit }, dto: IRemoveAllBasketItemsRequest) {
    try {
      const { data } = await apolloClient.mutate<IRemoveAllBasketItemsResponse, IRemoveAllBasketItemsRequest>({
        mutation: fetchRemoveAllCardItemSMutation,
        variables: {
          cart_id: dto.cart_id
        }
      })
      if (data?.removeAllItems?.message) {
        commit(BasketMutationTypes.SET_CART_QUERY, { items: [] })
        commit(BasketMutationTypes.SET_BASKET_ITEMS, [])
      }
    } catch (error: any) {
      console.error(error)
      throw new Error(error.message)
    }
  },
  async [BasketActionTypes.UPDATE_ITEMS] ({ commit }, dto: IUpdateItemsRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      await apolloClient.mutate<IUpdateItemsResponse, IUpdateItemsRequest>({
        mutation: fetchUpdateCartItemsMutation,
        variables: {
          cart_id: dto.cart_id,
          cart_items: dto.cart_items
        }
      })

      commit(BasketMutationTypes.SET_LOADING, false)
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('update items error:', error)
    }
  },
  async [BasketActionTypes.UPDATE_PRICES] ({ commit }, dto: IUpdatePricesRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const { data } = await apolloClient.mutate<IUpdatePricesResponse, IUpdatePricesRequest>({
        mutation: fetchUpdatePricesMutation,
        variables: {
          cart_id: dto.cart_id,
          delivery_date: dto.delivery_date,
          date_popup: dto.date_popup,
          payment_method: dto.payment_method,
          outlet_order_type: dto.outlet_order_type,
          delivery_method: dto.delivery_method,
          flexDelivery: dto.flexDelivery,
          payment_method_title: dto.payment_method_title,
          note_for_driver: dto.note_for_driver,
          customer_order_number: dto.customer_order_number,
          empties_return: dto.empties_return
        }
      })
      if (data?.updatePrices) {
        commit(BasketMutationTypes.SET_PRICES, data?.updatePrices)
        commit(BasketMutationTypes.SET_DEFAULT_EMPTIES, data?.updatePrices?.cart?.empties)
        commit(BasketMutationTypes.SET_LOADING, false)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_LOADING, false)
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('update prices error:', error)
    }
  },
  async [BasketActionTypes.FETCH_SHIPPING_ADDRESS] ({ commit }, dto: ISetShippingAddressRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetShippingAddressResponse, ISetShippingAddressRequest>({
        mutation: fetchShippingAddressMutation,
        variables: {
          cart_id: dto.cart_id,
          shipping_addresses: dto.shipping_addresses
        }
      })
      commit(BasketMutationTypes.SET_SHIPPING_ADDRESS, data?.setShippingAddressesOnCart)
    } catch (error) {
      console.error('shipping address error', error)
    }
  },
  async [BasketActionTypes.FETCH_SHIPPING_METHODS] ({ commit }, dto: ISetShippingMethodRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetShippingMethodResponse, ISetShippingMethodRequest>({
        mutation: fetchShippingMethodMutation,
        variables: {
          cart_id: dto.cart_id,
          shipping_methods: dto.shipping_methods
        }
      })
      const method = data?.setShippingMethodsOnCart?.cart.shipping_addresses as any
      if (typeof method !== 'undefined') {
        commit(BasketMutationTypes.SET_SHIPPING_METHODS, method[0]?.selected_shipping_method)
        // localStorage.setItem('shippingMethod', `${method[0]?.selected_shipping_method.carrier_code}_${method[0]?.selected_shipping_method.carrier_code}`)
      }
    } catch (error) {
      console.error('shipping methods error', error)
    }
  },
  async [BasketActionTypes.SET_PAYMENT_METHOD] ({ commit }, dto: ISetPaymentMethodRequest) {
    const { data } = await apolloClient.mutate<ISetPaymentMethodResponse, ISetPaymentMethodRequest>({
      mutation: fetchPaymentMethodMutation,
      variables: {
        cart_id: dto.cart_id,
        payment_method: dto.payment_method
      }
    })
    commit(BasketMutationTypes.PAYMENT_METHOD, data?.setPaymentMethodOnCart)
  },
  async [BasketActionTypes.FETCH_TRANSPORT_TYPE] ({ commit }, dto: ISetTransportTypeRequest) {
    try {
      const { data } = await apolloClient.mutate<ISetTransportTypeResponse, ISetTransportTypeRequest>({
        mutation: fetchTransportTypeMutation,
        variables: {
          transporttype: dto.transporttype,
          cart_id: dto.cart_id
        }
      })
      if (data?.setTransportType?.message) {
        commit(BasketMutationTypes.SET_TRANSPORT_TYPE, dto.transporttype)
        localStorage.setItem('shippingMethod', dto.transporttype)
      }
    } catch (error) {
      commit(BasketMutationTypes.SET_ERROR, error as IError[])
      console.error('transport type error', error)
    }
  },
  async [BasketActionTypes.PLACE_ORDER] ({ commit }, dto: IPlaceOrderRequest) {
    commit(BasketMutationTypes.SET_LOADING, true)
    try {
      const { data } = await apolloClient.mutate<IPlaceOrderResponse, IPlaceOrderRequest>({
        mutation: fetchPlaceOrderMutation,
        variables: {
          cart_id: dto.cart_id
        }
      })
      if (data) {
        commit(BasketMutationTypes.SET_ORDER, data?.placeOrder?.order)
        commit(BasketMutationTypes.SET_BASKET_ITEMS, [])
        localStorage.removeItem('customerOrderNumber')
        localStorage.removeItem('noteForDriver')
      }
      commit(BasketMutationTypes.SET_ERROR, [])
    } catch (error: any) {
      commit(BasketMutationTypes.SET_ERROR, [error?.message])
      commit(BasketMutationTypes.SET_LOADING, false)
      console.error('place order error', error)
    }
  },
  async [BasketActionTypes.SAVE_ORDER_FOR_LATER] ({ commit }, { cartId }: IAddSaveOrderForLaterRequest) {
    try {
      const { data } = await apolloClient.query<IAddSaveOrderForLaterResponse, IAddSaveOrderForLaterRequest>({
        query: addSaveOrderForLaterQuery,
        variables: {
          cartId
        }
      })
      commit(BasketMutationTypes.SET_BASKET_ITEMS, [])
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async [BasketActionTypes.FETCH_EMPTIES] ({ commit }, dto: IFetchEmptiesRequest) {
    try {
      const { data } = await apolloClient.query<IFetchEmptiesResponse, IFetchEmptiesRequest>({
        query: fetchEmptiesQuery,
        variables: {
          outletId: dto.outletId
        }
      })
      commit(BasketMutationTypes.SET_EMPTIES, data?.getCustomerEmpties?.empties)
      return data
    } catch (error) {
      console.error(error)
    }
  },
  async [BasketActionTypes.UPDATE_EMPTIES] ({ commit }, dto: IUpdateEmptiesRequest) {
    try {
      await apolloClient.query<IUpdateEmptiesResponse, IUpdateEmptiesRequest>({
        query: updateEmptiesQuery,
        variables: {
          empties: dto.empties,
          cart_id: dto.cart_id
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  async [BasketActionTypes.CREATE_SUGGESTED_CART] ({ commit }, dto: ICreateSuggestedCartRequest) {
    try {
      commit(BasketMutationTypes.SET_LOADING, true)
      const { data } = await apolloClient.query<ICreateSuggestedCartResponse, ICreateSuggestedCartRequest>({
        query: createSuggestedCartQuery,
        variables: {
          shipTo: dto.shipTo,
          cartId: dto?.cartId
        }
      })

      if (data) {
        commit(BasketMutationTypes.SET_BASKET_ID, data.cartId)
      }
      commit(BasketMutationTypes.SET_LOADING, false)
    } catch (error) {
      console.error(error)
      commit(BasketMutationTypes.SET_ERROR, [error as IError])
      commit(BasketMutationTypes.SET_LOADING, false)
    }
  },
  async [BasketActionTypes.FETCH_ORDER_SUMMARY_QUERY] ({ commit }, dto: ICartQueryRequest) {
    const { data } = await apolloClient.mutate<ICartQueryResponse, ICartQueryRequest>({
      mutation: fetchOrderSummaryQuery,
      variables: {
        cart_id: dto.cart_id
      }
    })
    if (data) {
      commit(BasketMutationTypes.SET_CART_QUERY, data?.cart)
      commit(BasketMutationTypes.SET_DELIVERY_DATE, data?.cart?.delivery_date || '')
      commit(BasketMutationTypes.SET_BASKET_ITEMS, data?.cart?.items)
    }
  }
}

const BasketModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default BasketModule
