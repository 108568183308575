
import { defineComponent } from 'vue'
import BannerCarousel from './BannerCarousel.vue'

export default defineComponent({
  name: 'BannerSection',
  components: {
    BannerCarousel
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },
  methods: {
    joinUs () {
      this.$navigate('/login')
    }
  }
})
