
import {
  ISaveUserRequest
} from '@/store/modules/users/interfaces/selectedUser/api/saveUser/ISaveUserRequest'
import {
  ISaveUserChangePasswordRequest
} from '@/store/modules/users/interfaces/selectedUser/api/saveUser/ISaveUserChangePasswordRequest'
import { ISetUserActivateDeactivateRequest } from '@/store/modules/users/interfaces/users/api/fetchUsers/ISetUserActivateDeactivateRequest'
import { ISaveUserResponse } from '@/store/modules/users/interfaces/selectedUser/api/saveUser/ISaveUserResponse'
import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import { IRootState } from '@/store'

export const saveUserAPI = {
  async createUser (dto: ISaveUserRequest): Promise<ISaveUserResponse> {
    const response: AxiosResponse = await instance.post<ISaveUserResponse>('users/CreateNewUser', dto)
    return response.data
  },
  async updateUser (dto: ISaveUserRequest): Promise<ISaveUserResponse> {
    const response: AxiosResponse = await instance.put<ISaveUserResponse>(`users/UpdateUser/${dto.id}`, dto)
    return response.data
  },
  async changePassword (dto: ISaveUserChangePasswordRequest, state: IRootState): Promise<boolean> {
    const response: AxiosResponse = await instance.put<boolean>(`users/ChangePassword/${state.auth.user.UID}`, dto)
    return response.data
  },
  async setUserStatus (dto: ISetUserActivateDeactivateRequest): Promise<boolean> {
    const response: AxiosResponse = await instance.patch<boolean>('users/SetUserStatus', dto.request)
    return response.data
  }
}
