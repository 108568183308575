
export enum TicketsMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_TICKETS = 'SET_TICKETS',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_SUBCATEGORIES = 'SET_SUBCATEGORIES',
  SET_COMMENTS_FOR_TICKET = 'SET_COMMENTS_FOR_TICKET',
  SET_ATTACHMENTS_FOR_TICKET = 'SET_ATTACHMENTS_FOR_TICKET',
  SET_LAST_TICKET_CASE_NUMBER = 'SET_LAST_TICKET_CASE_NUMBER',
}
