import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  uri: '/graphql'
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      outletid: localStorage.getItem('shipToId') as string,
      datepopup: localStorage.getItem('deliveryDate') as string,
      store: localStorage.getItem('store'),
      outletdeliverymethod: localStorage.getItem('shippingMethod') as string ?? 'flatrate_flatrate',
      authorization: `Bearer ${sessionStorage.getItem('id_token')}`,
      onlyEmpties: localStorage.getItem('onlyEmpties') as string,
      noteForDriver: localStorage.getItem('noteForDriver'),
      customerOrderNumber: localStorage.getItem('customerOrderNumber'),
      lang: localStorage.getItem('lang'),
      customertype: localStorage.getItem('customertype'),
      beerOrder: localStorage.getItem('beerOrder'),
      productrecoenabled: sessionStorage.getItem('productrecoenabled')
    }
  }
})
const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    }
  }
})
