import gql from 'graphql-tag'

export const fetchSavedOrdersQuery = gql`
  query ($sap_id: [String!], $date_from: String, $date_to: String, $deliver_to: String, $bill_to: String, $currentPage: Int) {
    getSaveOrderForLater(
      deliver_to: $deliver_to
      bill_to: $bill_to
      date_from: $date_from
      date_to: $date_to
      sap_id: $sap_id
      pageSize: 10
      currentPage: $currentPage
      sort: "DESC"
    ) {
      page_info {
        total_unique_items
        total_items
        total_pages
      }
      items {
        id
        date
        total
        total_qty
        deliver_to
        bill_to
        saved_order_later
        is_active
        cart_id
      }
    }
  }
`

export const fetchSavedOrdersDetailQuery = gql`
  query ($cart_id: String!) {
    getSaveOrderDetails(cart_id: $cart_id, saved_for_later: true) {
      email
      transporttype
      ship_to_id
      delivery_date
      id
      grand_total_your_price_tax
      grand_total_your_price
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          error_message
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      items {
        product {
          name
          sku
          volume
        }
        quantity
        prices{
          your_price
          your_price_tax
          total_your_price_tax
        }
        errors {
          code
          message
        }
      }
      selected_payment_method {
        code
        title
      }
      prices {
        grand_total {
          value
          currency
        }
      }
    }
  }
`
