import gql from 'graphql-tag'

export const reOrderSavedOrder = gql`
query ($cart_id: String!) {
  reOrderSavedOrder(
    cart_id: $cart_id
  ) {
    message
  }
}`
