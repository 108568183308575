import gql from 'graphql-tag'

export const fetchRemoveAllCardItemSMutation = gql`
  query ($cart_id: String!) {
    removeAllItems (
      cart_id: $cart_id
    ) {
      message
    }
  }
`
