import gql from 'graphql-tag'

export const updateEmptiesQuery = gql`
query($empties: [UpdatedEmpties]!, $cart_id: String!) {
  updateEmpties(
   empties: $empties,
   cart_id: $cart_id
  )
  {
   message
  }
}
`
