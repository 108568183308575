<template>
  <div class="tutorial-modal">
    <Carousel ref="tutorialCarousel" @slide-start="handleSlideStart" @init="handleInit">
      <Slide v-for="(helpPage, index) in sortedHelpPages" :key="index">
        <div class="carousel__item">
          <h3>{{ helpPage.title }}</h3>
          <img v-if="helpPage.file.link" :src="helpPage.file.link" alt="" />
          <div v-html="helpPage.body"></div>
        </div>
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
    <div class="d-flex justify-content-between mt-4">
      <div>
        <asahi-button v-if="currentSlide > 0" color="secondary-favorite" @click="back">
          {{ $t('Back') }}
        </asahi-button>
      </div>
      <div>
        <asahi-button v-if="currentSlide === sortedHelpPages.length - 1" color="secondary-red" @click="done">
          {{ $t('Done') }}
        </asahi-button>
        <asahi-button v-else color="secondary-red" @click="next">{{ $t('Next') }}</asahi-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { AuthMutationTypes } from '@/store/modules/auth/interfaces/mutation-types'
import refreshAfterChangeLanguage from '@/mixins/refreshAfterChangeLanguage'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'TutorialModal',

  components: {
    Carousel,
    Pagination,
    Slide
  },

  mixins: [refreshAfterChangeLanguage],

  data () {
    return {
      currentSlide: 0
    }
  },

  props: {
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },

  created () {
    this.refresh()
  },

  computed: {
    helpPagesStore () {
      return this.$store.state.helpPages
    },

    sortedHelpPages () {
      return [...this.helpPagesStore.helpPages].sort((a, b) => (a.page || 0) - (b.page || 0))
    }
  },

  methods: {
    refresh () {
      this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
    },

    handleInit () {
      this.currentSlide = 0
    },

    handleSlideStart ({ slidingToIndex }) {
      this.currentSlide = slidingToIndex
    },

    done () {
      const userInfo = this.$store.state.auth.user
      if (userInfo.firstLogin === true) {
        userInfo.firstLogin = false
        this.$store.commit(AuthMutationTypes.SET_USER_INFO, userInfo)
        this.$store.dispatch(AuthActionTypes.SET_FIRST_LOGIN, {
          UID: userInfo.UID,
          firstLogin: false
        })
      }
      this.closeModal()
    },

    back () {
      this.$refs.tutorialCarousel.prev()
    },

    next () {
      this.$refs.tutorialCarousel.next()
    }
  }
})
</script>

<style lang="scss">
.tutorial-modal {
  img {
    max-width: 100%;
    max-height: 25vh;
    height: auto;
    margin: 0 auto;
  }

  h2 {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #9e8852;
  }

  h3 {
    font-size: 20px;
    line-height: 21px;
    color: #9e8852;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .carousel__slide {
    align-items: flex-start;
  }

  .carousel__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    ul li {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #000000;
      list-style: disc;
      text-align: justify;
    }
  }

  .carousel__pagination {
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }

  .carousel__pagination-button::after,
  .carousel__pagination-button:hover::after {
    width: 6px;
    height: 6px;
    background: #afafaf;
    opacity: 0.4;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .carousel__pagination-button--active::after,
  .carousel__pagination-button--active:hover::after,
  .carousel__pagination-button--active ~ *::after {
    opacity: 1;
    background: #c6243e;
    border-color: rgba(198, 36, 62, 0.2);
  }

  .carousel__pagination-button:focus {
    outline: none;
  }
}
</style>
