
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import refreshAfterChangeLanguage from '@/mixins/refreshAfterChangeLanguage'
import { mapState } from 'vuex'
import 'vue3-carousel/dist/carousel.css'

interface IBannersContent {
  id: string
  locationId?: string
  image: {
    link: string
    [key: string]: any,
    metadata : any
  }
  [key: string]: any
}

interface IBannersResponse {
  limit: number
  offset: number
  total: number
  results: Array<IBannersContent>
}
export default defineComponent({
  name: 'BannerCaraousel',

  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },

  props: {
    location: {
      type: String,
      default: ''
    }
  },

  mixins: [refreshAfterChangeLanguage],

  data: () => {
    return {
      slides: null as Array<IBannersContent> | null,
      isMobile: window.innerWidth < 576
    }
  },

  computed: {
    ...mapState('main', ['bannersConfig']),
    isLoggedIn () {
      return this.$store.state.auth.user.isLoggedIn
    },

    path () {
      const path = this.$route.name as string
      if (path === 'homepage' && this.isLoggedIn) return path + 'AfterLogin'
      return path
    }
  },

  watch: {
    path () {
      this.refresh()
    }
  },

  created () {
    this.refresh()
  },

  methods: {
    refresh () {
      this.getStaticData()
    },

    async getStaticData (oldData?: Array<IBannersContent>, { offset = 0, limit = 10 } = {}) {
      if (this.isLoggedIn) {
        await this.$store.dispatch('main/FETCH_BANNERS_CONFIG', { root: true })
      }
      const locationName = this.location !== '' ? this.location : this.path
      if (!locationName) return

      try {
        let apiPath = '/banners'
        // For italy display banner either logged or not logged in
        if (!this.isLoggedIn) {
          apiPath = '/publicbanners'
        }
        const response: AxiosResponse<IBannersResponse> = await instance.get(apiPath, {
          params: { offset, limit, locationId: locationName }
        })

        const data = (oldData || []).concat(response.data.results)

        this.slides = data

        if (response.data.total > data.length) {
          this.getStaticData(data, { offset: data.length, limit })
        }
      } catch (error) {
        console.error(error)
      }
    },
    onResize () {
      this.isMobile = window.innerWidth < 576
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  }
})
