import axios from 'axios'
import { constants } from '@/constants'

export const instance = axios.create({
  baseURL: '/api/v1/portal/',
  timeout: 55000
  // go to https://customerportal-fe-qas.azurewebsites.net/ and rewrite old token
})

instance.interceptors.request.use(
  (configParam) => {
    const idToken = sessionStorage.getItem('id_token')
    configParam.headers.Authorization = idToken
    const language = localStorage.getItem('lang')
    if (language) {
      configParam.params = { language, ...configParam.params }
    } else {
      const languageValue = constants.application.theme === 'peroni' ? 'it' : 'cs'
      localStorage.setItem('lang', languageValue)
      configParam.params = { languageValue, ...configParam.params }
    }

    // console.info('interceptors', configParam.params.language, configParam.headers.Authorization)
    return configParam
  },

  (error) => {
    return Promise.reject(error)
  }
)
