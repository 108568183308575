import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { IArticlesState } from '@/store/modules/articles/interfaces/IArticlesState'
import { ArticlesMutationType } from '@/store/modules/articles/interfaces/mutation-type'
import { IArticlesMutations } from '@/store/modules/articles/interfaces/IArticleMutation'
import { IArticlesActions } from '@/store/modules/articles/interfaces/IArticlesAction'
import { ArticlesActionType } from '@/store/modules/articles/interfaces/action-type'
import {
  IFetchArticlesRequest
} from '@/store/modules/articles/interfaces/articles/api/fetchArticles/IFetchArticlesRequest'
import { articlesAPI } from '@/store/modules/articles/api/getArticles'
import {
  IFetchArticlesResponse
} from '@/store/modules/articles/interfaces/articles/api/fetchArticles/IFetchArticlesResponse'
import {
  IFetchSelectedArticleRequest
} from '@/store/modules/articles/interfaces/selectedArticle/api/fetchSelectedArticle/IFetchSelectedArticleRequest'
import { IArticle } from '@/store/modules/articles/interfaces/IArticle'

const state = (): IArticlesState => ({
  articles: {
    total: 0,
    offset: 0,
    limit: 0,
    results: []
  },
  selectedArticle: null,
  loading: false
})

const mutations: MutationTree<IArticlesState> & IArticlesMutations = {
  [ArticlesMutationType.SET_LOADING] (state: IArticlesState, payload: boolean) {
    state.loading = payload
  },
  async [ArticlesMutationType.SET_ARTICLES] (state: IArticlesState, payload: IFetchArticlesResponse) {
    state.articles = payload
  },
  async [ArticlesMutationType.SET_SELECTED_ARTICLE] (state: IArticlesState, payload: IArticle) {
    state.selectedArticle = payload
  }
}

const actions: ActionTree<IArticlesState, IRootState> & IArticlesActions = {
  async [ArticlesActionType.FETCH_ARTICLES] ({ commit }, dto: IFetchArticlesRequest) {
    commit(ArticlesMutationType.SET_LOADING, true)
    const data = await articlesAPI.fetchArticles(dto)
    commit(ArticlesMutationType.SET_ARTICLES, data)
    commit(ArticlesMutationType.SET_LOADING, false)
  },
  async [ArticlesActionType.FETCH_SELECTED_ARTICLE] ({ commit }, dto: IFetchSelectedArticleRequest) {
    commit(ArticlesMutationType.SET_LOADING, true)
    const data = await articlesAPI.fetchSelectedArticle(dto)
    commit(ArticlesMutationType.SET_SELECTED_ARTICLE, data)
    commit(ArticlesMutationType.SET_LOADING, false)
  }
}

const ArticlesModule = {
  state,
  mutations,
  actions
}

export default ArticlesModule
