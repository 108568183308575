import gql from 'graphql-tag'

export const fetchWishlistQuery = gql(`
query($currentPage:Int, $pageSize:Int)
 {
 customer {
    wishlists {
      id
      items_count
      items_v2(currentPage:$currentPage,pageSize:$pageSize){
        shipto_item_count
        items {
          id
          quantity
          product {
            uid
            sku
          }
        }
        page_info{

          current_page
          page_size
          total_pages
          }
      }
    }
    }
  }
`)
