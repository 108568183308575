
export enum MainMutationType {
  SET_SSR_RENDERED = 'SET_SSR_RENDERED',
  SET_ROUTE_LOADING = 'SET_ROUTE_LOADING',
  SET_PAGE_NOT_FOUND = 'SET_PAGE_NOT_FOUND',
  SET_HISTORY = 'SET_HISTORY',
  SET_CONFIG = 'SET_CONFIG',
  SET_BANNERS_CONFIG = 'SET_BANNERS_CONFIG',
  SET_HOLIDAYS_CONFIG = 'SET_HOLIDAYS_CONFIG',
  SET_PERMISSIONS = 'SET_PERMISSIONS'
}
