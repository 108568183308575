import { IRootState } from '@/store'
import { IOrderReview } from '@/store/modules/common/interface/IOrderReview'
import { ActionTree, MutationTree } from 'vuex'
import { CommonActionType } from './action-type'
import { AugmentedCommonActionType } from './ICommonActions'
import { ICommonMutations } from './ICommonMutations'
import { IDropdownItems } from './interface/IDropdownItems'
import { CommonMutationType } from './mutation-type'
import {
  getOrderTransportTypesAPI,
  getShipmentPlacesAPI,
  getOrderStatusAPI,
  getOrderStatusITAPI,
  getOrderDeliveryStatusITAPI
} from './services/common.api'
export interface ICommonState {
  orderReview: IOrderReview
  shipmentPlacesList: IDropdownItems[]
  orderTransportTypesList: IDropdownItems[]
  orderStatusList: IDropdownItems[],
  orderDeliveryStatusListIT: IDropdownItems[],
  orderStatusListIT: IDropdownItems[],
}

const state = (): ICommonState => ({
  orderReview: {} as IOrderReview,
  shipmentPlacesList: [],
  orderTransportTypesList: [],
  orderStatusList: [],
  orderDeliveryStatusListIT: [],
  orderStatusListIT: []
})

const getters = {
  getOrderReview (state: ICommonState) {
    return state.orderReview
  },
  getShipmentPlaces (state: ICommonState) {
    return state.shipmentPlacesList
  },
  getOrderTransportTypes (state: ICommonState) {
    return state.orderTransportTypesList
  },
  getOrderStatus (state: ICommonState) {
    return state.orderStatusList
  }
}

const actions: ActionTree<ICommonState, IRootState> = {
  async [CommonActionType.FETCH_SHIPMENT_PLACES_LIST] ({ commit }: AugmentedCommonActionType) {
    try {
      const response = await getShipmentPlacesAPI()
      commit(CommonMutationType.SET_SHIPMENT_PLACES_LIST, response)
    } catch (error) {
      console.error(error)
    }
  },
  async [CommonActionType.FETCH_ORDER_TRANSPORT_TYPES_LIST] ({ commit }: AugmentedCommonActionType) {
    try {
      const response = await getOrderTransportTypesAPI()
      let options = []
      options = response?.orderTypesDetailsDto.map((ot: any) => {
        return {
          label: ot.orderTypeDescription,
          value: ot.orderType
        }
      })
      commit(CommonMutationType.SET_ORDER_TRANSPORT_TYPES_LIST, options)
    } catch (error) {
      console.error(error)
    }
  },
  async [CommonActionType.FETCH_ORDER_STATUS_LIST] ({ commit }: any, { language }: any) {
    try {
      const response = await getOrderStatusAPI()
      commit(CommonMutationType.SET_ORDER_STATUS_LIST, response[language])
    } catch (error) {
      console.error(error)
    }
  },
  async [CommonActionType.FETCH_ORDER_STATUS_LIST_IT] ({ commit }: any) {
    try {
      const response = await getOrderStatusITAPI()
      commit(CommonMutationType.SET_ORDER_STATUS_LIST_IT, response)
    } catch (error) {
      console.error(error)
    }
  },
  async [CommonActionType.FETCH_ORDER_DELIVERY_STATUS_LIST_IT] ({ commit }: any) {
    try {
      const response = await getOrderDeliveryStatusITAPI()
      commit(CommonMutationType.SET_ORDER_DELIVERY_STATUS_LIST_IT, response)
    } catch (error) {
      console.error(error)
    }
  }
}

const mutations: MutationTree<ICommonState> & ICommonMutations = {
  [CommonMutationType.SET_ORDER_REVIEW] (state, payload) {
    state.orderReview = payload
  },
  [CommonMutationType.SET_SHIPMENT_PLACES_LIST] (state, payload) {
    state.shipmentPlacesList = payload
  },
  [CommonMutationType.SET_ORDER_TRANSPORT_TYPES_LIST] (state, payload) {
    state.orderTransportTypesList = payload
  },
  [CommonMutationType.SET_ORDER_STATUS_LIST] (state, payload) {
    state.orderStatusList = payload
  },
  [CommonMutationType.SET_ORDER_STATUS_LIST_IT] (state, payload) {
    state.orderStatusListIT = payload
  },
  [CommonMutationType.SET_ORDER_DELIVERY_STATUS_LIST_IT] (state, payload) {
    state.orderDeliveryStatusListIT = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
