import gql from 'graphql-tag'

export const fetchProductsQuery = gql`
  query (
    $sku: [String]
    $search: String
    $sort: ProductAttributeSortInput
    $brand: [String]
    $category_id: [String]
    $category_uid: [String]
    $filter_packaging: [String]
    $sub_brand: [String]
    $volume: [String]
    $pack_format: [String]
    $country_of_origin: [String]
    $currentPage: Int!
    $pageSize: Int = 25
    $is_peroni: Boolean = false
    $product_last: String
    $product_new: String
    $product_sale: String
    $product_seasonal: String
    $product_recommended: String
  ) {
    products(
      search: $search
      filter: {
        sku: { in: $sku }
        brand: { in: $brand }
        sub_brand: { in: $sub_brand }
        category_id: { in: $category_id }
        category_uid: { in: $category_uid }
        filter_packaging: { in: $filter_packaging }
        volume: { in: $volume }
        pack_format: { in: $pack_format }
        country_of_origin: { in: $country_of_origin }
        product_last: { eq: $product_last }
        product_new: { eq: $product_new }
        product_sale: { eq: $product_sale }
        product_seasonal: { eq: $product_seasonal }
        product_recommended: { eq: $product_recommended }
      }
      pageSize: $pageSize
      currentPage: $currentPage
      sort: $sort
    ) {
      total_count
      items {
        multi_deposit
        material_type
        name
        catalog_product_name
        deliveryDate
        sku
        packaging_enable
        your_price
        favourites
        product_last
        product_new
        product_sale
        product_seasonal
        product_recommended
        product_outofstock
        number_of_bottles_gabbie
        number_of_products_on_layer
        number_of_items_on_pallet
        number_of_layers_per_pallet
        description {
          html
        }
        features @include(if: $is_peroni) {
          beer_type
          province
          alcohol_content
          conservation_term
        }
        small_image {
          url
        }
        cart_fields {
          packaging_cart
          layer_cart
          pallets_cart
        }
        ... on BundleProduct {
          your_price_sum
          your_price_sum_tax
          items {
            option_id
            title
            sku
            type
            required
            position
            your_price_sum
            your_price_sum_tax
            options {
              uid
              id
              label
              quantity
              can_change_quantity
              price
              price_type
              is_default
              position
              product {
                id
                name
                catalog_product_name
                sku
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                  }
                }
                your_price
              }
            }
          }
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
    }
  }
`
