export const constants = {
  menuWithoutLogin: process.env.VUE_APP_MENU_WITHOUT_LOGIN?.split(','),
  // TODO: get rid of the hardcoded values
  saml: {
    eLearningURL: process.env.VUE_APP_CDC_ELEARNING,
    pubMenuURL: process.env.VUE_APP_CDC_PUBMENU,
    empties: process.env.VUE_APP_EMPTIES_REDIRECT_URL
  },
  links: {
    termsOfUse: {
      sk: 'https://asahilivestorage.file.core.windows.net/magentouat/2023_0214_ASAHI_LIVE_TERMS_CZ_EN.pdf?sv=2021-12-02&ss=bfqt&srt=o&sp=rtfx&se=2025-03-19T03:10:09Z&st=2023-03-18T19:10:09Z&spr=https&sig=UOOkWa8ErqyUsStsk%2Fb4cMu6mvrSCPLRHPUXA%2FfinKY%3D',
      en: 'https://asahilivestorage.file.core.windows.net/magentouat/2023_0214_ASAHI_LIVE_TERMS_CZ_EN.pdf?sv=2021-12-02&ss=bfqt&srt=o&sp=rtfx&se=2025-03-19T03:10:09Z&st=2023-03-18T19:10:09Z&spr=https&sig=UOOkWa8ErqyUsStsk%2Fb4cMu6mvrSCPLRHPUXA%2FfinKY%3D',
      cs: 'https://asahilivestorage.file.core.windows.net/magentouat/2023_0214_ASAHI_LIVE_TERMS_CZ_EN.pdf?sv=2021-12-02&ss=bfqt&srt=o&sp=rtfx&se=2025-03-19T03:10:09Z&st=2023-03-18T19:10:09Z&spr=https&sig=UOOkWa8ErqyUsStsk%2Fb4cMu6mvrSCPLRHPUXA%2FfinKY%3D',
      it: 'https://alproditsa.file.core.windows.net/magentoit/content/legal/2023_0214_ASAHI_LIVE_PERONI_TERMS_ITA_EN.docx%20REV%20LEG_v2.pdf?sv=2021-12-02&ss=bfqt&srt=o&sp=r&se=2026-02-24T19:07:09Z&st=2023-04-18T10:07:09Z&spr=https&sig=rZJSag1ul%2ffidABF7VKjTuIODzTTtwkSBGi4%2f9MoMD4%3D',
      de: ''
    },
    marketingMaterialBrandBookURL: process.env.VUE_APP_MM_BRANDBOOK,
    marketingMaterialGuidelineURL: process.env.VUE_APP_MM_GUIDELINE,
    marketingMaterialGuidelineURL_IT: process.env.VUE_APP_MM_GUIDELINE_IT,
    social: {
      fb: process.env.VUE_APP_SOCIAL_FB,
      linkedIn: process.env.VUE_APP_SOCIAL_LINKEDIN,
      insta: process.env.VUE_APP_SOCIAL_INSTA
    },
    footer: {
      corporateInformation: process.env.VUE_APP_FOOTER_CORPORATE_INFORMATION,
      privacyPolicy: process.env.VUE_APP_FOOTER_PRIVACY_POLICY,
      cookies: process.env.VUE_APP_FOOTER_COOKIES,
      corporateGovernance: process.env.VUE_APP_FOOTER_CORPORATE_GOVERNANCE
    }
  },
  application: {
    pageTitle: process.env.VUE_APP_DEFAULT_PAGE_TITLE,
    theme: process.env.VUE_APP_THEME,
    contactUsEmail: process.env.VUE_APP_CONTACT_US_MAIL,
    contactUsEmailSK: process.env.VUE_APP_CONTACT_US_MAIL_SK,
    contactUs: process.env.VUE_APP_CONTACT_US,
    contactUsSK: process.env.VUE_APP_CONTACT_US_SK
  },
  gigyaConfig: {
    screenSet: process.env.VUE_APP_SCREENSET,
    startScreen: process.env.VUE_APP_STARTSCREEN,
    forgotScreen: process.env.VUE_APP_FORGOTSCREEN,
    resetScreen: process.env.VUE_APP_RESETSCREEN
  },
  gtm: {
    containerId: process.env.VUE_APP_GTM_CONTAINER_ID
  },
  cartWeight: {
    minLimit: 1,
    minOrderReach: 11999,
    minMotriceDiscount: 12000,
    motriceDiscountReached: 15999,
    minAutotrenoDiscount: 16000,
    AutotrenoDiscountNotReached: 25259,
    minAutotrenoDiscountNotReached: 25260,
    autotrenoDiscountReached: 29299,
    maxOrderReached: 29300,
    maxEmptiesWeightReached: 50000
  }
} as const
