import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "top-carousel mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_ctx.slides?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Carousel, {
          autoplay: _ctx.slides?.length > 1 ? (_ctx.isLoggedIn ? _ctx.bannersConfig : 3000) : 0,
          "wrap-around": true
        }, _createSlots({
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide) => {
              return (_openBlock(), _createBlock(_component_Slide, {
                key: slide.id
              }, {
                default: _withCtx(() => [
                  (_ctx.slides?.length > 1)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "carousel__item",
                        style: _normalizeStyle(`background-image: url(${slide?.image?.link})`)
                      }, null, 4))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "carousel__item",
                        style: _normalizeStyle([{'background-image': `url(${slide?.image?.link})`}, !_ctx.isMobile ? {'min-height': `${slide?.image.metadata?.height}px`} : {}])
                      }, null, 4))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 2
        }, [
          (_ctx.slides?.length !== 1)
            ? {
                name: "addons",
                fn: _withCtx(() => [
                  _createVNode(_component_Navigation),
                  _createVNode(_component_Pagination)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["autoplay"])
      ]))
    : _createCommentVNode("", true)
}