import gql from 'graphql-tag'

export const fetchEmptiesQuery = gql`
query ($outletId: String!) {
  getCustomerEmpties (
    outletId: $outletId
  ) {
    empties {
      material
      date
      name
      weight
      small_image
      number_of_bottles_gabbie
    }
  }
}
`
