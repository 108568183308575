import {
  createRouter as createAsahiRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
  NavigationGuardNext
} from 'vue-router'
import { Store } from 'vuex'
import { IRootState } from '@/store'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { constants } from '@/constants'
import { nextTick } from 'vue'
import { IPermissionsConfig } from '@/store/modules/main/intefaces/IConfig'

const history = createWebHistory()

export type AsahiRouter = Router & { navigate: (url: string | CustomEvent) => void }

function getValueByLabel (arrayData: IPermissionsConfig | string, searchString: string) {
  // Parse the JSON array if it hasn't been parsed already
  if (typeof arrayData === 'string') {
    arrayData = JSON.parse(arrayData)
  }
  if (searchString === 'Home Page' || searchString === 'Empties Management' || searchString === 'Product Recommendation') {
    return true
  }

  if (Array.isArray(arrayData) && arrayData.length) {
    let result: string | false = false
    arrayData.forEach((item) => {
      if (item.label === searchString && item.value === '1') {
        result = item.value
        // Early return if a match is found
      }
    })
    return result
  }
  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createRoutes (store: Store<IRootState>): RouteRecordRaw[] {
  const checkPermission = (next: NavigationGuardNext, key: string) => {
    function proceed () {
      const permissionData = store.state?.main?.permissions
      if (store.getters.getIsFullLoaded() === true) {
        if (store.state.auth.user.allAvailablePermission.includes(key) &&
        getValueByLabel(permissionData, key)
        ) {
          next()
        } else {
          next({ name: 'Error403' })
        }
      }
    }

    if (store.getters.getIsFullLoaded() !== true
    // && !Object.keys(store.state?.main?.permissions).length
    ) {
      store.dispatch('main/FETCH_PERMISSIONS', { root: true })
      window.gigya.hasSession().then(
        window.gigya.socialize.getUserInfo({
          callback: (res: any) => {
            if (res.UID != null) {
              store.commit('SET_AUTH_USER', res)
              store.dispatch(AuthActionTypes.GET_USER_INFO, res.UID)
            } else {
              store.dispatch('logout')
              next({ name: 'login' })
            }
          }
        })
      )

      store.watch(store.getters.getIsFullLoaded, function () {
        if (store.getters.getIsFullLoaded() === true &&
            Object.keys(store.state?.main?.permissions).length) {
          proceed()
        }
      })
    } else {
      proceed()
    }
  }

  return [
    {
      path: '/',
      name: 'homepage',
      meta: {
        requireAuthentication: constants.application.theme === 'prazdroj',
        title: 'Home page'
      },
      beforeEnter: (to, from, next) => {
        if (constants.application.theme === 'prazdroj') {
          return checkPermission(next, 'Home Page')
        } else {
          return next()
        }
      },
      component: () => import(/* webpackChunkName: "homepage" */ '../pages/HomePage.vue')
    },
    {
      path: '/about-peroni',
      name: 'aboutPeroni',
      meta: { title: 'About peroni' },
      component: () => import(/* webpackChunkName: "homepage" */ '../pages/AboutPeroniPage.vue')
    },
    {
      path: '/verification',
      name: 'userVerification',
      meta: { isUserVerification: true, title: 'User verification' },
      component: () => import(/* webpackChunkName: "verification" */ '../pages/UserVerificationPage.vue')
    },
    {
      path: '/catalog/products',
      name: 'products',
      meta: { isProducts: 'product', requireAuthentication: true, title: 'Products catalogue' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "products" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/catalog/saved',
      name: 'savedOrders',
      meta: { isProducts: 'savedOrders', requireAuthentication: true, title: 'Saved orders' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "products" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/catalog/suggested-cart',
      name: 'suggested-cart',
      meta: { requireAuthentication: true, title: 'Suggested Cart' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Product Recommendation'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/SuggestedCartPage.vue')
    },
    {
      path: '/catalog/:id',
      name: 'productsDetail',
      meta: { requireAuthentication: true, title: 'Product details' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "productsDetail" */ '../pages/ProductPage.vue')
    },
    {
      path: '/catalog/favorites',
      name: 'favorites',
      meta: { isProducts: 'favorites', requireAuthentication: true, title: 'My favorites' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/CatalogPage.vue')
    },
    {
      path: '/orders/history',
      name: 'orders-history',
      meta: { tab: 'history', requireAuthentication: true, title: 'Orders history' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Orders'),
      component: () => import('@/pages/OrdersPage.vue')
    },
    {
      path: '/orders/saved',
      name: 'saved-orders',
      meta: { tab: 'saved', requireAuthentication: true, title: 'Saved orders' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Orders'),
      component: () => import('@/pages/OrdersPage.vue')
    },
    {
      path: '/profile/details',
      name: 'details',
      meta: { tab: 'details', requireAuthentication: true, title: 'My profile' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Profile'),
      component: () => import(/* webpackChunkName: "profile" */ '../pages/ProfilePage.vue')
    },
    {
      path: '/profile/management',
      name: 'management',
      meta: { tab: 'management', requireAuthentication: true, title: 'User management' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Profile'),
      component: () => import(/* webpackChunkName: "profile" */ '../pages/ProfilePage.vue')
    },
    {
      path: '/profile/company',
      name: 'company',
      meta: { tab: 'company', requireAuthentication: true, title: 'My company details' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Profile'),
      component: () => import(/* webpackChunkName: "profile" */ '../pages/ProfilePage.vue')
    },
    {
      path: '/contracts',
      name: 'contracts',
      meta: { requireAuthentication: true, title: 'Contracts' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Contracts'),
      component: () => import('../pages/ContractsPage.vue')
    },
    {
      path: '/reporting',
      name: 'reporting',
      meta: { requireAuthentication: true, title: 'Reporting' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Reporting'),
      component: () => import('../pages/ReportingPage.vue')
    },
    {
      path: '/my-documents',
      name: 'myDocuments',
      meta: { requireAuthentication: true, title: 'My documents' },
      beforeEnter: (to, from, next) => checkPermission(next, 'My Documents'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/MyDocumentsPage.vue')
    },
    {
      path: '/marketing-materials',
      name: 'marketingMaterials',
      meta: { requireAuthentication: true, title: 'Marketing materials' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Marketing Materials'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/FilesPage.vue')
    },
    {
      path: '/news',
      name: 'newsEvents',
      meta: { requireAuthentication: constants.application.theme === 'prazdroj', title: 'News' },
      beforeEnter: (to, from, next) => {
        if (constants.application.theme === 'prazdroj') {
          return checkPermission(next, 'News & Articles')
        } else {
          return next()
        }
      },
      component: () => import(/* webpackChunkName: "news" */ '../pages/NewsPage.vue')
    },
    {
      path: '/training',
      name: 'training',
      meta: { requireAuthentication: true, title: 'Training & learning' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Training & Learning'),
      component: () => import(/* webpackChunkName: "news" */ '../pages/TrainingLearningPage.vue')
    },
    {
      path: '/news/:path',
      name: 'newsItem',
      meta: { requireAuthentication: constants.application.theme === 'prazdroj', title: 'News item' },
      beforeEnter: (to, from, next) => {
        if (constants.application.theme === 'prazdroj') {
          return checkPermission(next, 'News & Articles')
        } else {
          return next()
        }
      },
      component: () => import(/* webpackChunkName: "news" */ '../pages/ArticlePage.vue')
    },
    {
      path: '/support/:path',
      name: 'support',
      meta: { requireAuthentication: true, title: 'Support' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Support'),
      component: () => import(/* webpackChunkName: "favorites" */ '../pages/SupportPage.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { noPortalChrome: true, title: 'Login' }, // noPortalChrome: true if don't want to display side menu & main header e.g. login page
      component: () => import(/* webpackChunkName: "login" */ '../pages/LoginPage.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      meta: { title: 'Forgot password' },
      component: () => import(/* webpackChunkName: "forgot password" */ '../pages/ForgotPasswordPage.vue')
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      meta: { title: 'Reset password' },
      component: () => import(/* webpackChunkName: "reset password" */ '../pages/ResetPasswordPage.vue')
    },
    {
      path: '/cart',
      name: 'cart',
      meta: { hideFooter: true, requireAuthentication: true, title: 'My cart' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "cart" */ '../pages/CartPage.vue')
    },
    {
      path: '/order-summary',
      name: 'orderSummary',
      meta: { requireAuthentication: true, title: 'Order summary' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "orderSummary" */ '../pages/OrderSummary.vue')
    },
    {
      path: '/estratto-conto',
      name: 'estrattoConto',
      meta: { requireAuthentication: true, title: 'Order summary' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Empties Management'),
      component: () => import(/* webpackChunkName: "orderSummary" */ '../pages/EstrattoContoPage.vue')
    },
    {
      path: '/empties-summary',
      name: 'emptiesSummary',
      meta: { requireAuthentication: true, title: 'Empties summary' },
      beforeEnter: (to, from, next) => checkPermission(next, 'Catalogue'),
      component: () => import(/* webpackChunkName: "emptiesSummary" */ '../pages/EmptiesSummary.vue')
    },
    {
      path: '/thanks',
      name: 'thanks',
      meta: { requireAuthentication: true, title: 'Thanks' },
      component: () => import(/* webpackChunkName: "thanks" */ '../pages/ThanksPage.vue')
    },
    {
      path: '/ko',
      name: 'ko',
      meta: { requireAuthentication: true, error: true, title: 'Thanks' },
      component: () => import(/* webpackChunkName: "ko" */ '../pages/ThanksPage.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      meta: { title: 'Contact' },
      component: () => import(/* webpackChunkName: "contact" */ '../pages/ContactPage.vue')
    },
    {
      path: '/saml-error',
      name: 'saml-error',
      meta: { noPortalChrome: true, title: 'SAML error' }, // noPortalChrome: true if don't want to display side menu & main header e.g. login page
      component: () => import(/* webpackChunkName: "files" */ '../pages/ErrorSAMLPage.vue')
    },
    {
      path: '/error403',
      name: 'Error403',
      meta: { title: 'Error' },
      component: () => import(/* webpackChunkName: "error403" */ '../pages/Error403Page.vue')
    },
    {
      path: '/maintenance',
      name: 'MaintenancePage',
      meta: { title: 'Maintenance' },
      component: () => import(/* webpackChunkName: "maintenance" */ '../pages/MaintenancePage.vue')
    },
    // this MUST be the last route
    {
      path: '/:pathMatch(.*)*',
      name: 'Error404',
      meta: { title: 'Error404' },
      component: () => import(/* webpackChunkName: "error404" */ '../pages/Error404Page.vue')
    }
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createRouter (store: Store<IRootState>): AsahiRouter {
  const routes = createRoutes(store)

  const router = createAsahiRouter({
    routes,
    history,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }

      if (to.query.c && from.params.productUrl === to.params.productUrl) {
        return {}
      }

      if (
        to.name === 'products' && from.name === 'products' &&
          to.path === from.path && to.query.page === from.query.page
      ) {
        return {}
      }

      if (to.hash) {
        return { el: to.hash }
      }

      return { top: 0 }
    }
  }) as AsahiRouter

  // https://next.router.vuejs.org/guide/advanced/navigation-guards.html#optional-third-argument-next
  router.beforeEach(async (to) => {
    const DEFAULT_TITLE = constants.application.pageTitle
    nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE
    })
    // disable data fetching for ssr-rendered 404 pages
    if (store.state.main.ssrRendered && store.state.main.pageNotFound) {
      store.commit('main/SET_SSR_RENDERED', false)
    }
    store.commit('main/SET_PAGE_NOT_FOUND', true)

    return true
  })

  router.afterEach(async (to, from) => {
    store.commit('main/SET_ROUTE_LOADING', false)
    const DEFAULT_TITLE = constants.application.pageTitle
    nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE
    })
    store.commit('main/SET_HISTORY', from)

    return true
  })

  router.navigate = (url: string | CustomEvent) => {
    const target =
      typeof url === 'string'
        ? new URL(url, window.location.toString())
        : new URL(url.detail?.href ? url.detail.href : url.detail, window.location.toString())
    if (
      target.origin === window.location.origin &&
      target.pathname === window.location.pathname &&
      target.search === window.location.search
    ) {
      if (target.hash === '') return
      const element = document.querySelector(target.hash) as HTMLElement
      window.scrollBy({
        top: element ? element.getBoundingClientRect().top : 0,
        left: 0,
        behavior: 'smooth'
      })
    } else if (target.origin === window.location.origin) {
      router.push(target.pathname + target.search + target.hash)
    } else {
      window.location.assign(target.href)
    }
  }

  return router
}
