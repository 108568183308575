import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { DocumentActionType } from '@/store/modules/documents/interfaces/action-type'
import { DocumentMutationType } from '@/store/modules/documents/interfaces/mutation-type'
import { IDocumentsState } from '@/store/modules/documents/interfaces/IDocumenstState'
import { IDocumentsMutations } from '@/store/modules/documents/interfaces/IDocumentsMutations'
import { IDocument } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { documentsAPI } from '@/store/modules/documents/api/documents-api'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { IDocumentsActions } from '@/store/modules/documents/interfaces/IDocumentsActions'
import { ITesiDocument } from './interfaces/api/ITesiDocuments/ITesiDocumentsResponse'
import { ICablogDocument } from './interfaces/api/ICablogDocuments/ICablogDocumentsResponse'
import { ITesiDocumentsRequest } from './interfaces/api/ITesiDocuments/ITesiDocumentsRequest'
import { ICablogDocumentsRequest } from './interfaces/api/ICablogDocuments/ICablogDocumentsRequest'
import { IDDTVDocumentRequest } from './interfaces/api/IDDTVDocuments/IDDTVDocument'

const state = (): IDocumentsState => ({
  documents: [],
  tesiDocuments: [],
  cablogDocuments: [],
  ddtvDocuments: [],
  loading: false,
  errors: null
})

const mutations: MutationTree<IDocumentsState> & IDocumentsMutations = {
  [DocumentMutationType.SET_LOADING] (state: IDocumentsState, payload: boolean) {
    state.loading = payload
  },
  [DocumentMutationType.SET_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.documents = payload
  },
  [DocumentMutationType.SET_TESI_DOCUMENTS] (state: IDocumentsState, payload: ITesiDocument[]) {
    state.tesiDocuments = payload
  },
  [DocumentMutationType.SET_CABLOG_DOCUMENTS] (state: IDocumentsState, payload: ICablogDocument[]) {
    state.cablogDocuments = payload
  },
  [DocumentMutationType.SET_ERROR] (state: IDocumentsState, payload: any) {
    state.errors = payload
  },
  [DocumentMutationType.SET_DDTV_DOCUMENT] (state: IDocumentsState, payload: any) {
    state.ddtvDocuments = payload
  }
}

const actions: ActionTree<IDocumentsState, IRootState> & IDocumentsActions = {
  async [DocumentActionType.FETCH_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getDocuments(dto)
      commit(DocumentMutationType.SET_DOCUMENTS, response.documentList)
      commit(DocumentMutationType.SET_LOADING, false)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },
  async [DocumentActionType.FETCH_TESI_DOCUMENTS] ({ commit }, dto: ITesiDocumentsRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getTesiDocuments(dto)
      commit(DocumentMutationType.SET_TESI_DOCUMENTS, response.documentList)
      commit(DocumentMutationType.SET_LOADING, false)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_TESI_DOCUMENTS, [])
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },
  async [DocumentActionType.FETCH_CABLOG_DOCUMENTS] ({ commit }, dto: ICablogDocumentsRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getCablogDocuments(dto)
      commit(DocumentMutationType.SET_CABLOG_DOCUMENTS, response.documentList)
      commit(DocumentMutationType.SET_LOADING, false)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_CABLOG_DOCUMENTS, [])
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },
  async [DocumentActionType.FETCH_DDTV_DOCUMENT] ({ commit }, dto: IDDTVDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const response = await documentsAPI.getDDTVDocument(dto)
      commit(DocumentMutationType.SET_DDTV_DOCUMENT, response)
      commit(DocumentMutationType.SET_LOADING, false)
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_DDTV_DOCUMENT, '')
      commit(DocumentMutationType.SET_LOADING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
