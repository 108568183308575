import gql from 'graphql-tag'

export const fetchUpdateCartItemsMutation = gql(`
mutation ($cart_id: String!, $cart_items: [CartItemUpdateInput]!) {
  updateCartItems(
    input: {
      cart_id: $cart_id
      cart_items: $cart_items
    }
  ) {
      __typename
    }
  }
`)
