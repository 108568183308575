export enum BasketActionTypes {
  'CREATE_EMPTY_CART' = 'CREATE_EMPTY_CART',
  'FETCH_CART_QUERY' = 'FETCH_CART_QUERY',
  'FETCH_PRODUCTS' = 'FETCH_PRODUCTS',
  'FETCH_BASKET_ID' = 'FETCH_BASKET_ID',
  'FETCH_ADD_TO_BASKET' = 'FETCH_ADD_TO_BASKET',
  'REMOVE_ITEM' = 'REMOVE_ITEM',
  'UPDATE_ITEMS' = 'UPDATE_ITEMS',
  'UPDATE_PRICES' = 'UPDATE_PRICES',
  'FETCH_BILLING_ADDRESS' = 'FETCH_BILLING_ADDRESS',
  'FETCH_SHIPPING_ADDRESS' = 'FETCH_SHIPPING_ADDRESS',
  'FETCH_SHIPPING_METHODS' = 'FETCH_SHIPPING_METHODS',
  'SET_PAYMENT_METHOD' = 'SET_PAYMENT_METHOD',
  'PLACE_ORDER' = 'PLACE_ORDER',
  'FETCH_TRANSPORT_TYPE' = 'FETCH_TRANSPORT_TYPE',
  SAVE_ORDER_FOR_LATER = 'SAVE_ORDER_FOR_LATER',
  FETCH_MIN_CART_QUERY = 'FETCH_MIN_CART_QUERY',
  FETCH_EMPTIES = 'FETCH_EMPTIES',
  UPDATE_EMPTIES = 'UPDATE_EMPTIES',
  REMOVE_ALL_ITEMS = 'REMOVE_ALL_ITEMS',
  'CREATE_SUGGESTED_CART' = 'CREATE_SUGGESTED_CART',
  FETCH_ORDER_SUMMARY_QUERY = 'FETCH_ORDER_SUMMARY_QUERY'
}
