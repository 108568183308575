
import { defineComponent } from 'vue'
import { IAuthUser } from '@/store/modules/auth/interfaces/IAuthUser'
import { permissionSchema } from '@/store/modules/users/interfaces/IUser'
import { RolePermissionDetails } from '@/store/modules/users/interfaces/ICompany'

const validateUser = (user: IAuthUser) => {
  const errorMessages = []

  if (user.isLoggedIn) {
    if (!(user.country != null && user.country !== '')) {
      errorMessages.push('Country is not fill')
    }

    if (
      !user.permissionSchema?.some((permission: permissionSchema) =>
        permission.rpDetails?.some((rp: RolePermissionDetails) => rp.customerType != null && rp.customerType !== '')
      )
    ) {
      errorMessages.push('Customer type is not fill')
    }
  }
  console.error(errorMessages.join(', '))
  return ''// errorMessages.join(', ')
}

export default defineComponent({
  name: 'UserToolbar',

  data: () => ({}),

  computed: {
    errors () {
      return validateUser(this.$store.state.auth.user)
    },

    isFullLoaded () {
      return this.$store.state.auth.isFullLoaded
    }
  }
})
