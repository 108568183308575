import {
  GTM_SUGGESTED_CART,
  SUGGESTED_CART_ORDER_PLACED_WITH_MODIFICATION
} from '@/utils/gtmConstants'

export default {
  methods: {
    gtmSuggestedCartOrderCancelTag () {
      const hasSuggestedCart = !!sessionStorage.getItem(GTM_SUGGESTED_CART)
      if (hasSuggestedCart) {
        sessionStorage.setItem(SUGGESTED_CART_ORDER_PLACED_WITH_MODIFICATION, 'true')
      }
    }
  }
}
