import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { IFilesState } from '@/store/modules/files/interfaces/IFilesState'
import { IFetchFilesResponse } from '@/store/modules/files/interfaces/files/api/fetchFiles/IFetchFilesResponse'
import { FilesMutationType } from '@/store/modules/files/interfaces/mutation-type'
import { IFilesMutations } from '@/store/modules/files/interfaces/IFilesMutations'
import { FilesActionType } from '@/store/modules/files/interfaces/action-type'
import { IFilesActions } from '@/store/modules/files/interfaces/IFilesActions'
import { IFetchFilesRequest } from '@/store/modules/files/interfaces/files/api/fetchFiles/IFetchFilesRequest'
import { filesAPI } from '@/store/modules/files/api/getFiles'
import { IBulkFilesRequest } from '@/store/modules/files/interfaces/files/api/bulkFiles/IBulkFilesRequest'

const state = (): IFilesState => ({
  files: {
    total: 0,
    offset: 0,
    limit: 0,
    results: []
  },
  loading: false,
  bulkFile: {} as Blob,
  brandList: {} as IFetchFilesResponse
})

const mutations: MutationTree<IFilesState> & IFilesMutations = {
  [FilesMutationType.SET_LOADING] (state: IFilesState, payload: boolean) {
    state.loading = payload
  },
  async [FilesMutationType.SET_FILES] (state: IFilesState, payload: IFetchFilesResponse) {
    state.files = payload
  },
  async [FilesMutationType.SET_BRAND_LIST] (state: IFilesState, payload: IFetchFilesResponse) {
    state.brandList = payload
  },
  async [FilesMutationType.SET_BULK_FILES] (state: IFilesState, payload: Blob) {
    state.bulkFile = payload
  }
}

const actions: ActionTree<IFilesState, IRootState> & IFilesActions = {
  async [FilesActionType.FETCH_FILES] ({ commit }, dto: IFetchFilesRequest) {
    commit(FilesMutationType.SET_LOADING, true)
    const data = await filesAPI.fetchFiles(dto)
    commit(FilesMutationType.SET_FILES, data)
    commit(FilesMutationType.SET_LOADING, false)
  },
  async [FilesActionType.FETCH_BULK_FILES] ({ commit }, dto: IBulkFilesRequest) {
    commit(FilesMutationType.SET_LOADING, true)
    const data = await filesAPI.fetchBulkFiles(dto)
    commit(FilesMutationType.SET_BULK_FILES, data)
    commit(FilesMutationType.SET_LOADING, false)
  },
  async [FilesActionType.FETCH_BRAND_LIST] ({ commit }) {
    commit(FilesMutationType.SET_LOADING, true)
    const data = await filesAPI.fetchBrandList()
    commit(FilesMutationType.SET_BRAND_LIST, data)
    commit(FilesMutationType.SET_LOADING, false)
  }
}

const FilesModule = {
  state,
  mutations,
  actions
}

export default FilesModule
