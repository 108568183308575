import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import { IDocumentResponse } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { ITesiDocumentsRequest } from '../interfaces/api/ITesiDocuments/ITesiDocumentsRequest'
import { ITesiDocumentResponse } from '../interfaces/api/ITesiDocuments/ITesiDocumentsResponse'
import { ICablogDocumentsRequest } from '../interfaces/api/ICablogDocuments/ICablogDocumentsRequest'
import { ICablogDocumentResponse } from '../interfaces/api/ICablogDocuments/ICablogDocumentsResponse'
import { IDDTVDocumentRequest } from '../interfaces/api/IDDTVDocuments/IDDTVDocument'

export const documentsAPI = {
  async getDocuments (dto: IDocumentRequest): Promise<IDocumentResponse> {
    const response: AxiosResponse<IDocumentResponse> = await instance.get('documents', { params: dto })
    return response.data
  },
  async getTesiDocuments (dto: ITesiDocumentsRequest): Promise<ITesiDocumentResponse> {
    const response: AxiosResponse<ITesiDocumentResponse> = await instance.get('documents/GetTesiDetails', {
      params: dto
    })
    return response.data
  },
  async getCablogDocuments (dto: ICablogDocumentsRequest): Promise<ICablogDocumentResponse> {
    const response: AxiosResponse<ICablogDocumentResponse> = await instance.get('documents/GetCablogDetails', {
      params: dto
    })
    return response.data
  },
  async getDDTVDocument (dto: IDDTVDocumentRequest): Promise<string> {
    const response: AxiosResponse<string> = await instance.get('documents/GetDDTVDetails', {
      params: dto
    })
    return response.data
  }
}
