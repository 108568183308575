import gql from 'graphql-tag'

export const fetchOrderSummaryQuery = gql(`
query ($cart_id: String!) {
  cart(
      cart_id: $cart_id
  ) {
    note_for_driver
    customer_order_number
    delivery_date
    delivery_method
    outlet_details {
      street
      city
    }
    payer_details {
      street
      city
    }
    id
    total_quantity
    multi_deposit
    ship_to_id
    items {
      id
      uid
      prices {
        total_item_discount {
          value
        }
      }
      product {
        name
        catalog_product_name
        number_of_bottles_gabbie
        sku
        material_type
      }
      quantity
    }
  }
}
`)
