export enum OrderActionType{
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_SAVED_ORDERS = 'FETCH_SAVED_ORDERS',
  FETCH_SAVED_ORDER_DETAILS = 'FETCH_SAVED_ORDER_DETAILS',
  REORDER_SAVED_ORDER = 'REORDER_SAVED_ORDER',
  DELETE_SAVED_ORDER = 'DELETE_SAVED_ORDER',
  REORDER_HISTORY_ORDER = 'REORDER_HISTORY_ORDER',
  CANCEL_HISTORY_ORDER = 'CANCEL_HISTORY_ORDER',
  GET_BEER_ORDER_STATUS = 'GET_BEER_ORDER_STATUS'
}
