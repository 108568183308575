import gql from 'graphql-tag'

export const fetchUpdatePricesMutation = gql(`
mutation (
  $cart_id: String!, $delivery_date: String!, $date_popup: String!, $customer_order_number: String, $note_for_driver: String, $payment_method: String!,
  $outlet_order_type: String!, $delivery_method: String!, $flexDelivery: String!, $payment_method_title: String!, $empties_return: String
) {
  updatePrices(
    input: {
      cart_id: $cart_id
      delivery_date: $delivery_date
      date_popup: $date_popup
      customer_order_number: $customer_order_number
      payment_method: $payment_method
      outlet_order_type: $outlet_order_type
      delivery_method: $delivery_method
      flexDelivery: $flexDelivery
      payment_method_title: $payment_method_title
      note_for_driver: $note_for_driver
      empties_return: $empties_return
    }
  ) {
      success,
      message,
      can_proceed,
      status_message,
      minicart_status_message,
      payment_method,
      delivery_date,
      delivery_date_expired,
      order_type,
      delivery_method,
      ship_to_id,
      flexible_delivery,
      order_items {
        name,
        catalog_product_name,
        sku,
        packaging,
        volume,
        is_wishlist,
        image_main,
        row_total,
        custom_price,
        list_price,
        excise_duty,
        discounts,
        total_discounts,
        full_price,
        discounted_price,
        listprice_with_discount,
        row_total_list_price,
        row_total_discount,
        row_total_excise,
        row_total_without_vat,
        row_total_with_vat,
        quantity
      }
      cart {
        note_for_driver
        customer_order_number
        grand_total_basic_price
        grand_total_basic_price_tax
        grand_total_your_price
        grand_total_your_price_tax
        grand_total_multi_deposit
        selected_payment_method {
          code
          title
        }
        empties {
          parent_product
          sku
          qty
          name
          number_of_bottles_gabbie
        }
        progress_bar_data {
          isFullTruck
          iMinimumOrderPallet
          iMaximumOrderPallet
          iMinimumOrderWeight
          iMaximumOrderWeight
          iMaximumOrderCash
          sOutletDeliveryMethod
          iTotalCartWeight
          iTotalCartPalletQty
        }
      },
      total_base_price,
      total_your_price,
      total_quantity,
    }
  }
`)
