import type { Component } from 'vue'
import ConfirmModal from '@/components/common/ConfirmModal.vue'

interface ShowComponentDialogOptions {
  component: Component | null
  props?: Record<string, any>
  listeners?: Record<string, any>
  showCloseButton?: boolean
  backdropDismiss?: boolean
}

interface ShowConfirmModalOptions {
  title?: string
  message?: string
  icon?: string
  confirm?: string
  cancel?: string
}

export interface ShowComponentDialogHandlerOptions extends ShowComponentDialogOptions {
  id: string
  onClose: () => void
}

export default {
  openDialogCount: 0,
  showModalHandler: undefined as ((args: ShowComponentDialogHandlerOptions) => void) | undefined,

  async showModal (args: ShowComponentDialogOptions) {
    if (!this.showModalHandler) return

    try {
      this.openDialogCount++
      const promise = new Promise((resolve) => {
        this.showModalHandler &&
          this.showModalHandler({
            ...args,
            id: 'modal-' + (this.openDialogCount + 1),
            onClose: () => {
              resolve(true)
            }
          })
      })
      return await promise
    } catch (e: any) {
      throw new Error(e.message)
    } finally {
      this.openDialogCount--
    }
  },

  showConfirmModal (args: ShowConfirmModalOptions) {
    const promise = new Promise((resolve, reject) => {
      this.showModal({
        component: ConfirmModal,
        showCloseButton: false,
        backdropDismiss: false,
        props: args,
        listeners: {
          confirm: async (data: any) => {
            resolve(data)
          },
          cancel: () => {
            reject(new Error('User canceled the action'))
          }
        }
      })
    })
    return promise
  }
}
