import { AxiosResponse } from 'axios'
import { shipmentPlaces, orderStatus } from '../../../../utils/mockAPIresponse'
import { IOrderTypeResponse, IOrderStatusResponse } from '../interface/IOrderReview'
import { instance } from '@/store/api-instance'

async function getShipmentPlacesAPI () {
  return await JSON.parse(shipmentPlaces)
}

async function getOrderTransportTypesAPI () {
  const response: AxiosResponse = await instance.get<IOrderTypeResponse>('ordertypes')
  return response?.data
}

async function getOrderStatusAPI () {
  return await JSON.parse(orderStatus)
}
async function getOrderStatusITAPI () {
  const response: AxiosResponse = await instance.get<IOrderStatusResponse>('orderstatus')
  return response?.data
}
async function getOrderDeliveryStatusITAPI () {
  const response: AxiosResponse = await instance.get<IOrderStatusResponse>('orderdeliverystatus')
  return response?.data
}

export {
  getShipmentPlacesAPI,
  getOrderTransportTypesAPI,
  getOrderStatusAPI,
  getOrderStatusITAPI,
  getOrderDeliveryStatusITAPI
}
