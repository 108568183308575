import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bbd1d8e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner-container mt-4" }
const _hoisted_2 = {
  key: 0,
  class: "button-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_asahi_button = _resolveComponent("asahi-button")!
  const _component_BannerCarousel = _resolveComponent("BannerCarousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.user.isLoggedIn && this.$route.name=== 'homepage')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_asahi_button, {
            color: "secondary-red",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.joinUs()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Join US')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_BannerCarousel)
  ]))
}