import { instance } from '@/store/api-instance'
import { IEstrattoResponse, IRiepilogoResponse, IDettaglioResponse } from '@/store/modules/estratto/interfaces/api/IEstrattoResponse'
import { IEstrattoRequest, IRiepilogoRequest, IDettaglioRequest } from '@/store/modules/estratto/interfaces/api/IEstrattoRequest'
import { AxiosResponse } from 'axios'

export const estrattoAPI = {
  async fetchEstrattoConto (dto: IEstrattoRequest): Promise<IEstrattoResponse> {
    const response: AxiosResponse = await instance.get<IEstrattoResponse>('ordersstatement', { params: dto })
    return response.data
  },
  async fetchRiepilogo (dto: IRiepilogoRequest): Promise<IRiepilogoResponse> {
    const response: AxiosResponse = await instance.get<IRiepilogoResponse>('ordersstatement/materials', { params: dto })
    return response.data
  },
  async fetchDettaglio (dto: IDettaglioRequest): Promise<IDettaglioResponse> {
    const response: AxiosResponse = await instance.get<IDettaglioResponse>('ordersstatement/movements', { params: dto })
    return response.data
  }
}
