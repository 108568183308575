import gql from 'graphql-tag'

export const fetchProductQuery = gql`
  query ($sku: String, $is_peroni: Boolean = false) {
    products(filter: { sku: { eq: $sku } }, pageSize: 25, currentPage: 1, sort: { name: ASC }) {
      items {
        name
        material_type
        packaging_enable
        deliveryDate
        sku
        number_of_products_on_layer
        description {
          html
        }
        ean
        ean_ea
        features @include(if: $is_peroni) {
          fermentation
          beer_type
          province
          taste
          custom_color
          alcohol_content
          food_pairing
          conservation_term
          service_temperature
        }
        technical @include(if: $is_peroni) {
          package_dimensions
          pallet_dimensions
          pallet_weight
          gross_weight_for_one_cas
        }
        your_price
        your_price_tax
        base_price
        base_price_tax
        downloads
        favourites
        promo
        weight
        gross_weight_for_one_cas
        sap_sub_brand_code
        pallet_weight
        packaging
        options_container
        beer_type
        number_of_layers_per_pallet
        number_of_items_on_pallet
        number_of_products_on_layer
        product_name_sap
        sku_product_detail_page
        brand
        alcohol_content
        hectolitres_for_one_cas_frontend
        hectolitres_per_pallet
        number_of_items_in_box
        other_info_shelf_life
        pack_format
        pack_material
        sap_pack
        sap_pack_configuration
        returnable_package
        sub_brand
        volume
        country_of_origin
        catalog_product_name
        filter_packaging
        product_last
        product_new
        product_sale
        product_seasonal
        product_outofstock
        product_recommended
        canonical_url
        url_path
        url_key
        url_rewrites {
          url
          parameters {
            name
            value
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
          }
        }
        swatch_image
        small_image {
          url
        }
        thumbnail {
          url
        }
        image {
          url
        }
        media_gallery {
          url
        }
        related_products {
          uid
          name
          sku
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
            maximum_price {
              final_price {
                value
                currency
              }
            }
          }
          swatch_image
          small_image {
            url
          }
          thumbnail {
            url
          }
          image {
            url
          }
          media_gallery {
            url
          }
        }
        ... on BundleProduct {
          your_price_sum
          your_price_sum_tax
          items {
            option_id
            title
            sku
            type
            required
            position
            your_price_sum
            your_price_sum_tax
            options {
              uid
              id
              label
              quantity
              can_change_quantity
              price
              price_type
              is_default
              position
              product {
                id
                name
                sku
                price_range {
                  minimum_price {
                    regular_price {
                      value
                      currency
                    }
                  }
                }
                your_price
                your_price_tax
              }
            }
          }
        }
      }
      page_info {
        page_size
      }
    }
  }
`
